import React from "react";
import "./About.css";
import {
  FaReact,
  FaCss3Alt,
  FaHtml5,
  FaLess,
  FaCode,
  FaUserGraduate,
} from "react-icons/fa";
import { SiJavascript, SiFirebase, SiPhp } from "react-icons/si";
function About() {
  return (
    <div>
      <section id="about" className="about">
        <article className="about-container">
          <h2>
            About<span> Me</span>
          </h2>
          <p className="about-text">
            I am passionate about creating web experiences that improve the
            lives of those around me.I am constantly trying to acquire new
            skills that would help improve my career and attain goals that were
            previously considered impossible. I try to be a better version of
            myself every day as my goal is to become а Fullstack Web Developer.
          </p>
        </article>

        <article className="about-info">
          <div className="education-container">
            <h2>
              <i>
                <FaUserGraduate />
              </i>{" "}
              Education
            </h2>
            <div className="education">
              <h4>Technical University Varna</h4>
              <span>2016-2020</span>

              <p>
                At the University I studied many different programming
                languages. The main focus was on web programming languages.
                There I acquired ability to analyze, break and solve problems,
                applying simple solutions.
              </p>
            </div>
            <div className="education">
              <h4>NET IT Academy</h4>
              <span>2020-2021</span>
              <p>
                In the NET IT Academy I learned what it's like to work on real
                projects. There I also managed to cover the gaps from the
                University and worked on group projects with deadlines.
              </p>
            </div>
          </div>
          <div className="skills">
            <h2>
              <i>
                <FaCode />
              </i>{" "}
              Coding Skills
            </h2>
            <div className="skills-container">
              <div>
                <p>
                  <i className="HTML">
                    <FaHtml5 />
                  </i>
                  HTML
                </p>
                <p>
                  <i className="CSS">
                    <FaCss3Alt />
                  </i>
                  CSS
                </p>
                <p>
                  <i className="LESS">
                    <FaLess />
                  </i>
                  LESS
                </p>
                <p>
                  <i className="React">
                    <SiPhp />
                  </i>{" "}
                  PHP{" "}
                </p>
              </div>

              <div>
                <p>
                  <i className="JavaScript">
                    <SiJavascript />
                  </i>{" "}
                  JavaScript
                </p>
                <p>
                  <i className="React">
                    <FaReact />
                  </i>{" "}
                  React{" "}
                </p>
                <p>
                  <i className="Firebase">
                    <SiFirebase />
                  </i>
                  Firebase
                </p>
                <p>
                  <i className="React">
                    <FaReact />
                  </i>{" "}
                  React Native{" "}
                </p>
              </div>
            </div>
          </div>
        </article>
      </section>
    </div>
  );
}

export default About;
