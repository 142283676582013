import React from 'react'
import About from './About'
import Contacts from './Contacts'
import Main from './Main'
import Portfolio from './Portfolio/Portfolio'
import {FaHome,FaEnvelope} from 'react-icons/fa'
import {GoPerson} from 'react-icons/go'
import {MdWork} from 'react-icons/md'
export  const components = [
    {
        component:<Main key="home"/>,
        name:"home",
        icon:<FaHome/>
    },
    {
        component:<About key="about"/>,
        name:"about",
        icon:<GoPerson/>
    },
    {
        component:<Portfolio key="portfolio"/>,
        name:"portfolio",
        icon:<MdWork/>
    },
    {
        component:<Contacts key="contacts"/>,
        name:"contacts",
        icon:<FaEnvelope/>
    }
]


