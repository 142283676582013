import React from 'react'
import {GrLinkedin} from 'react-icons/gr'
function Main(props) {
 
    return (
    <div>
        <section id="home" className="main-section">
            <div className="container">
                <div className="img">
                    <img src="/images/projects/profile.jpg" alt="profile"/>
                    <a className="linkedIn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ivan-stoychev-171623204/">
                    <GrLinkedin />
                    </a>
                </div>          
                <h2>Ivan Stoychev</h2>
                <p>Software Engineer</p>
               
                <button className="hire" onClick={()=>{document.getElementById('contacts').click()}}>Email me</button>
            </div>
        </section>
    </div>    
    )
}

export default Main
