import React, { useRef,useEffect } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";

function SingleProject(props) {
    const project = useRef()
  
    return (
        <ScrollAnimation animateIn="animate__slideInLeft">
        <div className ="website">
            <div ref={project} class="website-info">
                <div className={`tech ${props.category}`}>
                    {props.icons.map(item=>{
                    
                        return <p><p id="icon" key={item.name} className={item.name}>{item.icon}</p>{item.name}</p>
                    })}
                
                </div>
                <div class="website-info-center">
                <h4 className="full-title">{props.name}</h4>
               
                <div className="demo-btn-container">
                    {props.site ? <a className="visit" href={props.site} target="_blank" rel="noreferrer">Visit Website</a>
                    :
                    <button class="demo-btn" onClick={()=>props.renderComponent(props.component)}>Demo</button>
                    }
                    {props.gitLink && <a className="git-link" href={props.gitLink} target="_blank" rel="noreferrer">Code</a>}
                    
                </div>
              
                    </div>
                <p className="full-text">{props.desc}</p>
                
           </div>
           <img src={props.img} alt="mygym"/>
        </div>
        </ScrollAnimation>
    )
}

export default SingleProject
