import React,{useState,useEffect} from 'react'
import Main from './pages/Main'
import $ from "jquery";
import {components} from './pages/components'
import './MainPage.css'
import {RiCloseCircleFill} from 'react-icons/ri'
import {GiHamburgerMenu} from 'react-icons/gi'

function MainPage() {
  
    const [component,setComponent] = useState(<Main key="home"/>)

    $(document).on("click", function(e){
        if( 
        $(e.target).closest(".header").length === 0 &&
        $(".header").hasClass("nav-active") &&
        $(e.target).closest(".open").length === 0
        ){
        $('.header').toggleClass('nav-active');
        }
    });
    useEffect(() => {
        const selectElement = (s) => document.querySelector(s);

    selectElement('.open').addEventListener('click',() => {
        selectElement('.close').style.display="inline-block";
        selectElement( '.header').classList.add('nav-active');
    });

    selectElement('.close').addEventListener('click',() => {
        selectElement( '.header').classList.remove('nav-active');
        selectElement('.close').style.display="none";
    });
    }, [])
   

    return (
        <main>
            <div className="animation-left">
        <div className="left-line-top"></div>
        <div className="left-line-bottom"></div>
    </div>
    <div className="animation-bottom">
        <div className="bottom-line-left"></div>
        <div className="bottom-line-middle"></div>
        <div className="bottom-line-right"></div>
    </div>
  
        <span className="mobile-menu open"><i><GiHamburgerMenu/></i> Menu </span>
       
           
    <header className="header">
        <div className="menu-icons close">
            <i><RiCloseCircleFill/></i>
        </div>
        <button className="title" onClick={()=>(setComponent(<Main key="home"/>))}>Ivan Stoychev</button>
        
        <nav className="main-nav">
            {components.map((item,index) =>{
                return(
                <button  
                    key={index}
                    id={item.name}
                    className={`${component.key===item.name ? "active" : null }`} 
                    onClick={()=>{
                        setComponent(item.component)
                        document.querySelector('.header').classList.remove('nav-active')
                        }}>
                    <span className="nav-icon">{item.icon}</span>
                    <span className="slider"></span> {item.name}
                </button>
                )
            })}
           
        </nav>
    </header>

        {component}
 
        </main>
    )
}

export default MainPage
