 export const data = [
    {
        id:"1",
        cardName:"rabbit",
        image:"images/memoryGame/rabbit.png",
    
    },
    {
        id:"2",
        cardName:"rabbit",
        image:"images/memoryGame/rabbit.png",
    },
    {
        id:"3",
        cardName:"cat",
        image:"images/memoryGame/cat.png"
       
    },
    {
        id:"4",
        cardName:"cat",
        image:"images/memoryGame/cat.png"
      
    },
    {
        id:"5",
        cardName:"dog",
        image:"images/memoryGame/dog.png"
   
    },
    {
        id:"6",
        cardName:"dog",
        image:"images/memoryGame/dog.png"
    
    },
    {
        id:"7",
        cardName:"wolf",
        image:"images/memoryGame/wolf.png"
    
    },
    {
        id:"8",
        cardName:"wolf",
        image:"images/memoryGame/wolf.png"
    },
    {
        id:"9",
        cardName:"hippo",
        image:"images/memoryGame/hippo.png"
       
    },
    {
        id:"10",
        cardName:"hippo",
        image:"images/memoryGame/hippo.png"
    },
    {
        id:"11",
        cardName:"bird",
        image:"images/memoryGame/bird.png"
    
    },
    {
        id:"12",
        cardName:"bird",
        image:"images/memoryGame/bird.png"
    
    },
]

