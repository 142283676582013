import React,{useState} from 'react'
import './Portfolio.css'
import SingleProject from './SingleProject'
import {items} from './data'
import {AiFillCloseSquare} from 'react-icons/ai'
import $ from 'jquery'

const allCategories = [...new Set(items.map((item)=> item.category))]
function Portfolio() {

    const [data,setData] = useState(items)
    const [categories,setCategories] = useState(allCategories)
    const [component,setComponent] = useState('')

    
    const renderComponent = (comp) =>{
     
        setComponent(comp)
        document.getElementById('portfolio').style.display='fixed'
        document.querySelector(".demo").style.display='flex'
    } 
    const filterItems = (category)=>{
        if(category === 'all' ){
            setData(items)
            setCategories(allCategories)
            return;
        }
   
        const newItems = items.filter((item)=>item.category===category)
     
        setCategories([category])
        setData(newItems);
    }
    const closeComponent = ()=>{
      
        document.querySelector('.demo').style.display="none";
     }

     const closeDemo = e =>{
         if(e.target.className==='demo'){
             e.target.style.display='none'
         }
     }
    return (
        <>
         
            <section id="portfolio" className="portfolio">
                <h2>Portfolio</h2>
                <div className="btn-container">
                    <button className={`btn ${categories.length>1 && 'btn-active'}`} onClick={()=>filterItems('all')}>All</button>
                    {allCategories.map((category,index) =>{
                        return(
                            <button 
                                key={index}
                                className={`btn ${categories[0]===category && categories.length<2 ? 'btn-active':'null'}`} 
                                onClick={()=>filterItems(category)}>
                                {category}
                            </button>
                        )
                    })}
                </div>
                {categories.map((category,index)=>{
                    return(
                        <div key={index}>
                            <h3 className="websites-title">{category}</h3>
                            <div className="websites-container">
                                {data.map(item=>{
                                    if(item.category===category){
                                    return <SingleProject
                                        renderComponent={renderComponent}
                                        key={item.name}
                                        {...item}
                                    />
                                    }
                                    
                                })}                  
                            </div>
                        </div>
                       
                    )
                })}
               
               <div className="demo" onClick={(e)=>closeDemo(e)}>
              
                        {component}
                        <button className="close-btn" onClick={closeComponent}><AiFillCloseSquare/> Close</button>
                </div>
            </section>
           
            </>
    )
}

export default Portfolio
