import React from "react";
import { GrLinkedin } from "react-icons/gr";
import "./Contacts.css";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
function Contacts() {
  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail.value)) {
      mail.style.background = "#1a1a1a";
      return true;
    } else if (mail.value === "") {
      alert("Please enter an email!");
      mail.style.background = "darkred";
      return false;
    } else {
      alert("Not a valid email!");
      mail.style.background = "darkred";
      return false;
    }
  }
  function sendEmail(e) {
    e.preventDefault();
    var name = document.getElementById("name");
    var email = document.getElementById("email");
    var subject = document.getElementById("subject");
    var message = document.getElementById("message");

    var check = 0;

    if (name.value === "") {
      name.style.background = "darkred";
      alert("Please enter a name!");
    } else {
      name.style.background = "#1a1a1a";
      check++;
    }

    if (ValidateEmail(email)) {
      check++;
    }

    if (check === 2) {
      // eslint-disable-next-line no-undef
      Email.send({
        Host: "smtp.gmail.com",
        Username: "vanpestoTest@gmail.com",
        Password: "pekisto7193",
        To: "vanpesto97@mail.bg",
        From: name.value + " - " + email.value,
        Subject: subject.value,
        Body: message.value,
      }).then((message) => alert("your message was sent successfully!"));
      name.value = "";
      email.value = "";
      subject.value = "";
      message.value = "";
    }
  }

  return (
    <section id="contacts" class="contacts">
      <h2>
        CONTACT <span>ME</span>
      </h2>
      <div class="contact-info">
        <div class="contact-info-item">
          <i>
            <FaPhone />
          </i>
          <h4>Call Me On</h4>
          <p>0883 200 604</p>
        </div>

        <div class="contact-info-item">
          <i>
            <MdLocationOn />
          </i>
          <h4>Living In</h4>
          <p>Varna</p>
        </div>

        <div class="contact-info-item">
          <i>
            <FaEnvelope />
          </i>
          <h4>Email</h4>
          <p>ivan.stoychev.dev@gmail.com</p>
        </div>

        <a
          style={{ color: "white" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/ivan-stoychev-171623204/"
          class="contact-info-item"
        >
          <i style={{ color: "#0A66C2" }}>
            <GrLinkedin />
          </i>
          <h4>LinkedIn</h4>
        </a>
      </div>
      <form class="form" onSubmit={sendEmail}>
        <div class="client-info">
          <input id="name" type="text" placeholder="Name*" required />
          <input id="email" type="text" placeholder="Email*" required />
        </div>
        <input id="subject" class="subject" type="text" placeholder="Subject" />
        <textarea id="message" placeholder="Your Message..."></textarea>
        <input class="btn-contacts" type="submit" value="Send Message" />
      </form>
    </section>
  );
}

export default Contacts;
