
import {FaReact,FaCss3Alt,FaHtml5} from 'react-icons/fa'
import {SiFirebase} from 'react-icons/si'
import {SiJavascript} from 'react-icons/si'
import Accordion from '../../small-react-projects/Accordion/Accordion'
import MainMenu from '../../small-react-projects/Menu/MainMenu'
import Reviews from '../../small-react-projects/Reviews/Reviews'
import MainTours from '../../small-react-projects/Tours/MainTours'
import Lorem from '../../small-react-projects/Lorem/Lorem'
import Colors from '../../small-react-projects/ColorGenerator/Colors'
import GroceryList from '../../small-react-projects/GroceryList/GroceryList'
import Cart from '../../small-react-projects/Cart/Cart'
import Weather from '../../small-react-projects/WeatherApp/Weather'
import MemoryGame from '../../small-react-projects/MemoryGame/MemoryGame'
export const items=[
    {
        name:"Social App",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            },
            {
                icon:<SiFirebase/>,
                name:"Firebase"
            }
        ],
        category:"bigger projects",
        img:"/images/projects/social-app.png",
        desc:"Social App where you can upload images and write posts. You can also like and comment" ,   
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/social-app/src",
        site:"https://social-app-a3253.web.app/"
    },
    {
        name:"SaveTheForest",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"bigger projects",
        img:"/images/projects/SaveTheForest.jpg",
        desc:"A browser game built with React. In the game you control three heroes with unique abilities. Make the heroes work together and use the best combinations of spells to defeat the monster that destroys the forest." ,   
        gitLink:"",
        site:"https://savetheforest-dedb2.web.app/"
    },
    {
        name:"MyGym",
        icons:[
            {
                icon:<FaHtml5/>,
                name:"HTML"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            },
            {
                icon:<SiJavascript/>,
                name:"JavaScript"
            }
        ],
        category:"bigger projects",
        img:"/images/projects/mygym-final.jpg",
        desc:"Website with multi pages for a Fitness center. There you can find information about the Fitness center, what they are doing, who are their coaches. Also you can see the available courses, center location and contacts.",    
        gitLink:"",
        site:"https://mygym-3ca7d.web.app"
    },
    {
        name:"MyFitness",
        icons:[
            {
                icon:<FaHtml5/>,
                name:"HTML"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            },
            {
                icon:<SiJavascript/>,
                name:"JavaScript"
            }
        ],
        category:"bigger projects",
        img:"/images/projects/myfitness-final.jpg",
        desc:"E-store website for selling supplements and training programs. You can sign up and write a comment for a training program or make an order. You can track your orders from your profile." ,   
        gitLink:"",
        site:"https://myfitness-32ec6.web.app/"
    },
    {
        name:"Pizza",
        icons:[
            {
                icon:<FaHtml5/>,
                name:"HTML"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            },
            {
                icon:<SiJavascript/>,
                name:"JavaScript"
            }
        ],
        category:"landing pages",
        img:"/images/projects/pizza.jpg",
        desc:"Landing page for an E-store where you can order a delicious pizza or a sweet dessert. " ,   
        gitLink:"",
        site:"https://pizza-ccede.web.app/"
    },
    {
        name:"Nature Tours",
        icons:[
            {
                icon:<FaHtml5/>,
                name:"HTML"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            },
            {
                icon:<SiJavascript/>,
                name:"JavaScript"
            }
        ],
        category:"landing pages",
        img:"/images/projects/tours.jpg",
        desc:"Beautiful landing page for a tour agency, that targets people who love nature and have adventurous spirit. " ,   
        gitLink:"",
        site:"https://tours-336df.web.app/"
    },
    {
        name:"Tacos",
        icons:[
            {
                icon:<FaHtml5/>,
                name:"HTML"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            },
            {
                icon:<SiJavascript/>,
                name:"JavaScript"
            }
        ],
        category:"landing pages",
        img:"/images/projects/tacos.png",
        desc:"Small and colorful landing page about tacos and a small gallery." ,   
        gitLink:"",
        site:"https://tacos-991a5.web.app/"
    },
    {
        name:"Weather App",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/weather.jpg",
        desc:"" ,   
        component: <Weather/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/WeatherApp"
    },
    {
        name:"Register/Login",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            },
            {
                icon:<SiFirebase/>,
                name:"Firebase"
            }
        ],
        category:"small react projects",
        img:"/images/projects/auth.png",
        desc:"" ,   
        component: "auth",
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/auth-development/src/Account",
        site:"https://auth-development-6df4e.web.app/"
    },
    {
        name:"Memory Game",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/memoryGame.png",
        desc:"" ,   
        component: <MemoryGame/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/MemoryGame"
    },
    {
        name:"Grocery List",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/groceryList.png",
        desc:"" ,   
        component: <GroceryList/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/GroceryList"
    },
    {
        name:"Shopping Cart",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/cart.png",
        desc:"" ,   
        component: <Cart/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/Cart"
    },
    {
        name:"Tours",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/tours-catalog.png",
        desc:"" ,   
        component: <MainTours/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/Tours"
    },
    {
        name:"Color Generator",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/colors.png",
        desc:"" ,   
        component: <Colors/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/ColorGenerator"
    },
    {
        name:"Menu",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/menu.png",
        desc:"" ,   
        component:<MainMenu/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/Menu"
    },
    {
        name:"Text Generator",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/lorem.png",
        desc:"" ,   
        component: <Lorem/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/Lorem"
    },
    {
        name:"Accordion",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/accordion.png",
        desc:"" ,   
        component: <Accordion/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/Accordion"
    },
    {
        name:"Reviews",
        icons:[
            {
                icon:<FaReact/>,
                name:"React"
            },
            {
                icon:<FaCss3Alt/>,
                name:"CSS"
            }
        ],
        category:"small react projects",
        img:"/images/projects/reviews.png",
        desc:"" ,   
        component: <Reviews/>,
        gitLink:"https://github.com/vanpesto/small-react-projects/tree/main/small-react-projects/Reviews"
    }
]