import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import MainPage from './MainPage'
function App() {
  return (
    <div>
    <Router>
      <Switch>
        <Route exact path="/" component={MainPage}></Route>
        {/* <Route exact path="/reviews" component={Reviews}></Route>
        <Route exact path="/accordion" component={Accordion}></Route>
        <Route exact path="/menu" component={MainMenu}></Route>
        <Route exact path="/lorem" component={Lorem}></Route>
        <Route exact path="/color" component={Colors}></Route> */}
      </Switch>
    </Router>
    </div>
  )
}

export default App
